<template>
  <div class="about">
  </div>
</template>
<script>
export default{
  methods:{
    logout(){
      this.$cookies.remove(this.$COOKIEPhase);
      this.$store.state.group_id = '';
      document.location = '/login';
    }
  },
  created(){
    this.logout();
  }
}
</script>
